@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 20px;
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  width: 20px;
  border-radius: 14px;
}

.row_table:hover .pencil_edit {
  opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.bg_core {
  background: #f9004d;
}

.text_core {
  color: #f9004d;
}

.border_core {
  border-color: #f9004d;
}

.bg_wom {
  background: #451287;
}

.text_wom {
  color: #451287;
}

.border_wom {
  border-color: #451287;
}

.bg_entel {
  background: #002efe;
}

.bg_entel[type="button"]:disabled {
  background-color: rgb(76 76 76 / 0.3);
  color: rgb(76 76 76 / var(--tw-text-opacity));
  border: 1px solid rgb(76 76 76 / var(--tw-text-opacity));
}

.text_entel {
  color: #002efe;
}

.border_entel {
  border-color: #002efe;
}

.notification-dot {
  position: absolute;
  top: 5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.word_break_container {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .materialize-select {
    @apply appearance-none w-full px-3 h-10 bg-transparent border-0 border-b-2 border-gray-300 rounded-none 
    focus:border-b-2 focus:border-indigo-600 focus:ring-0 transition-colors;
  }

  .materialize-input {
    @apply appearance-none w-full px-3 h-10 bg-transparent border-0 border-b-2 border-gray-300 rounded-none 
    focus:border-b-2 focus:border-indigo-600 focus:ring-0 transition-colors;
  }

  .materialize-checkbox {
    @apply w-4 h-4 rounded-sm border-2 border-gray-400 
    checked:border-indigo-600 checked:bg-indigo-600 
    focus:ring-2 focus:ring-indigo-200 transition-colors;
  }
}

/* Custom Scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 3px;
}

/* React Select Custom Styles */
.react-select-container .react-select__control {
  @apply border-gray-200 hover:border-gray-300 shadow-none;
}

.react-select-container .react-select__control--is-focused {
  @apply border-indigo-600 hover:border-indigo-600 shadow-none ring-2 ring-indigo-100;
}

.react-select-container .react-select__menu {
  @apply border border-gray-200 bg-white rounded-lg shadow-lg;
}

.react-select-container .react-select__option {
  @apply hover:bg-indigo-50 hover:text-indigo-600 cursor-pointer;
}

.react-select-container .react-select__option--is-selected {
  @apply bg-indigo-600 text-white;
}

.react-select-container .react-select__multi-value {
  @apply bg-indigo-50;
}

.react-select-container .react-select__multi-value__label {
  @apply text-indigo-700;
}

.react-select-container .react-select__multi-value__remove {
  @apply hover:bg-indigo-200 hover:text-indigo-700 rounded-r-sm;
}